import Callout from "../../../../src/components/widgetComponents/Callout";
import Collapse from "../../../../src/components/widgetComponents/Collapse";
import FigureImage from "../../../../src/components/widgetComponents/FigureImage";
import ExternalLink from "../../../../src/components/widgetComponents/ExternalLink";
import PostLink from "../../../../src/components/widgetComponents/PostLink";
import * as React from 'react';
export default {
  Callout,
  Collapse,
  FigureImage,
  ExternalLink,
  PostLink,
  React
};