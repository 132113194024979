import CodeBlock from "../../../../src/components/MDXComponents/CodeBlock";
import Callout from "../../../../src/components/widgetComponents/Callout";
import Collapse from "../../../../src/components/widgetComponents/Collapse";
import ExternalLink from "../../../../src/components/widgetComponents/ExternalLink";
import PostLink from "../../../../src/components/widgetComponents/PostLink";
import * as React from 'react';
export default {
  CodeBlock,
  Callout,
  Collapse,
  ExternalLink,
  PostLink,
  React
};