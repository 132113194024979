import Link from "../../../../src/components/widgetComponents/Link";
import ExternalLink from "../../../../src/components/widgetComponents/ExternalLink";
import Callout from "../../../../src/components/widgetComponents/Callout";
import PostLink from "../../../../src/components/widgetComponents/PostLink";
import * as React from 'react';
export default {
  Link,
  ExternalLink,
  Callout,
  PostLink,
  React
};