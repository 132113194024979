import Callout from "../../../../src/components/widgetComponents/Callout";
import CodeBlock from "../../../../src/components/MDXComponents/CodeBlock";
import Collapse from "../../../../src/components/widgetComponents/Collapse";
import ExternalLink from "../../../../src/components/widgetComponents/ExternalLink";
import * as React from 'react';
export default {
  Callout,
  CodeBlock,
  Collapse,
  ExternalLink,
  React
};