import Paragraph from "../../../../src/components/MDXComponents/Paragraph";
import Callout from "../../../../src/components/widgetComponents/Callout";
import ExternalLink from "../../../../src/components/widgetComponents/ExternalLink";
import PostLink from "../../../../src/components/widgetComponents/PostLink";
import * as React from 'react';
export default {
  Paragraph,
  Callout,
  ExternalLink,
  PostLink,
  React
};